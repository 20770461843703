// Chakra imports
import {Button, Flex, Grid, Link} from "@chakra-ui/react";
import React from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";

function Tables() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <Grid templateRows='1fr' width="100px" ml="auto" my="16px">
                <Link
                    w="100%"
                    href="#"
                >
                    <Button
                        fontSize="16px"
                        fontWeight="bold"
                        w="100%"
                        bg="teal"
                        _hover="none"
                        _active={{
                            bg: "white",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                        color="white"
                    >
                        Add Event
                    </Button>
                </Link>
        </Grid>
      <Authors
        title={"Aircraft out of service"}
        captions={["Tall/Description", "Station", "Status", "ATA", "Actions"]}
        data={tablesTableData}
      />
      {/*<Projects*/}
      {/*  title={"Projects Table"}*/}
      {/*  captions={["Companies", "Budget", "Status", "Completion", ""]}*/}
      {/*  data={dashboardTableData}*/}
      {/*/>*/}
    </Flex>
  );
}

export default Tables;
