// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    name: "Aircraft Maintenance",
    category: "account",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/AOS",
        name: "Aircraft Out of Service",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Tables,
        layout: "/admin",
      },
      {
        path: "/",
        name: "AOS Event Codes",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "AOS Root Causes",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "MX Manufacturer",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
    ]
  },
  {
    name: "Maintenance Applications",
    category: "Maintenance",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/",
        name: "Aircraft Panels",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "ATA Codes",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "Aircraft Zones",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
    ]
  },
  {
    name: "Source Documents",
    category: "Source",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/",
        name: "Source Document Type",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "Source Document Suggested Disposition",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "Source Document Issuer",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
    ]
  },
  {
    name: "Aircraft Components",
    category: "Components",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/",
        name: "MX Component Type",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "MX Component",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "MX Component Category",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
    ]
  },
  {
    name: "Aircraft Information",
    category: "Information",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/",
        name: "Allegiant Information Systems",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/",
        name: "AC Types",
        rtlName: "",
        icon: <DocumentIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
      },
    ]
  }
];
export default dashRoutes;
